<template>
  <Alerta/>
  <CropImagem/>
  <div class="views">
    <component :is="layout"/>
  </div>
</template>

<script setup>
import { computed, inject, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreAlerta } from './stores/index.js'
import { helperBloqueioBody } from './helpers/index.js'
import Alerta from './components/global/alerta/Alerta.vue'
import CropImagem from './components/global/cropImage/CropImagem.vue'

const { currentRoute } = useRouter()

const emitter = inject("emitter")
const storeAlerta = useStoreAlerta()

const layout = computed(() => {
  if(!currentRoute?.value?.name) return
  return currentRoute?.value.meta.layout || 'default-layout'
})

function mostrarAlerta(payload) {
  const dados = {
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  }
  storeAlerta.mostrarAlerta(dados)
}

function detectarModalAberto() {
  setInterval(() => helperBloqueioBody.bloquearBodyQuandoAbrirModal(), 500)
}

onMounted(() => {
  detectarModalAberto()
  emitter.on('mostrarAlerta', (payload) => mostrarAlerta(payload))
})
</script>
