export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: {
      title: 'Erro | UnicPages',
      layout: 'default-layout'
    }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../components/app/usuarios/Usuarios.vue'),
    meta: {
      logged: true,
      title: 'Usuários | UnicPages',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../components/app/perfil/Perfil.vue'),
    meta: {
      logged: true,
      title: 'Perfil do Admin | UnicPages',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/sites',
    name: 'sites',
    component: () => import('../components/app/sites/Sites.vue'),
    meta: {
      logged: true,
      title: 'Sites na Unic | UnicPages',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/dados',
    name: 'dados',
    component: () => import('../components/app/dados/Dados.vue'),
    meta: {
      logged: true,
      title: 'Dados da Unic | UnicPages',
      layout: 'navbar-layout'
    }
  }
]
